<template>

	<div>
		<van-nav-bar class="navbar" title="补扣项明细" fixed left-arrow @click-left="leftReturn" />
		<!-- 主体的列表们 -->
		<div class="head">
			<!-- <div class="verifyTime">
				确认时间：2020-12-12 12:45
			</div> -->
			<div class="bo-dy">
				<div style="text-align: center;"><img class="wamp" src="../../../assets/images/wamp.png" alt=""></div>
				<div class="money">{{actualAmount}}元</div>
				
				<div class="line"></div>
				<div>
          <div v-for="item in typeList" style="width: 100%;height: 25px;font-size: 15px">
            <div style="width: 100%;height: 25px;font-size: 14px;margin-left: 5px">
              <div class="" style="float: left;font-size: 14px; font-weight: 600;margin-left: 10px">{{item.dictionaryTitle}}</div>
              <div  style="float: left;font-size: 14px;margin-top: 3px;color: #979797">{{"(" + item.addtionTime + ")"}}</div>
              <div class="moneydet">{{item.amount}}元</div>
            </div>
          </div>
				</div>
				<div class="line"></div>
				<div class="real">
					<div class="realtitle">共计：</div>
					<div class="realmoneydet">{{actualAmount}}元</div>
				</div>
				<!-- <div v-if="salaryStatus == '1'" class="bottomNavigationB" @click="confrim">确认</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
		wageHistoryDetail
	} from "../../../getData/getData";
	import {
		getUserId,
		globaluserId,
		responseUtil,
		getStaffId
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "myOrder",
		data() {
			return {
				show: false,
				showPicker: false,
				isOccupationShow: false,
				loading: false,
				finished: false,
				certificateType: '',
				defaultIndex: '',
				swi: true,
				isLoading: false,
				totalPage: '',
				paylist: [],
				actualAmount: '',//实际金额
				salaryTime: '',//工资时间
				
				workClothes:'', //工装
				workCheck:'', //考勤
				NetworkPort:'', //网络端口
				insuranceSubsidy:'',//保险补助
				mutualFund:'',//互助金
				collectHouse:'',//收房年限奖励
				
				typeList:[],
				currentPage:1
				
				

			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar

		},
		created() {
			this.wageHistoryDetail()
			
		},
		//初始化
		mounted() {
			
		},
		methods: {
			
			//补扣项明细初始化接口
			wageHistoryDetail() {
				let that = this
				let initData = {};
				initData.user_id = '1'
				initData.staff_id = getStaffId()
				initData.salaryHistory_id = that.$route.query.salaryHistory_id
				initData.currentPage=that.currentPage
				initData.numberPage='100'
				console.log(initData)
				wageHistoryDetail(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						console.log(response)
						that.actualAmount = response.data.data.totalAmount
						that.typeList = response.data.data.data
						that.currentPage++
					})
				})
			},
			//初始化数据
			initData() {
				const that = this
				let initData = {
					user_id: globaluserId()
				}
			},
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},
			default () { //默认值
			},
		},

	}
</script>

<style scoped>
	.navbar {
		background-color: rgb(248, 248, 248);
	}

	.head {
		margin-top: 60px;
	}

	.verifyTime {
		font-size: 12px;
		color: #ACACAC;
		text-align: right;
		padding-right: 20px;
		padding-bottom: 10px;
	}

	.bo-dy {
		width: 90%;
		height: 500px;
		background-color: white;
		border-radius: 8px;
		margin: 0 auto;
	}

	.wamp {
		width: 60px;
		margin-top: 30px;
	}

	.money {
		text-align: center;
		color: #FF5D3B;
		font-weight: 600;
		font-size: 30px;
	}

	.month {
		font-size: 12px;
		color: #ACACAC;
		text-align: center;
		margin-top: 10px;
	}

	.line {
		width: 100%;
		border-top: #f8f8f8 2px solid;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.left {
		float: left;
		margin-left: 20px;
	}

	.title {
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 4px;
	}

	.right {
		float: right;
		margin-right: 20px;
	}
  .left {
    float: left;
  }

	.moneydet {
    float: right;
    margin-right: 20px;
		font-size: 14px;
		margin-bottom: 4px;
		color: gray;
		font-weight: 600;
	}

	.real{
		width: 100%;
		height: 40px;
		background-color: white;
		border-radius: 0 0 8px 8px;
	}

	.realtitle{
		font-size: 14px;
		font-weight: 600;
		margin-left: 20px;
		float: left;
	}

	.realmoneydet{
		font-size: 14px;
		color: gray;
		float: right;
		margin-right: 28px;
		color: #FF5D3B;
		font-weight: 600;
	}

	.bottomNavigationB {
		height: 50px;
		width: 100%;
		font-size: 16px;
		align-items: center;
		border-radius: 7px;
		background: linear-gradient(to right, #FFC274, #FF5D3B);
		color: white;
		line-height: 50px;
		font-family: PingFangSC-Semibold;
		text-align: center;
		margin-top: 45px;
	}
</style>
